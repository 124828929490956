import {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {RootReducer} from 'hsi/reducers/rootReducer';

// Components
import Heading from 'hsi/components/aria/Heading';
import ManagedGridTable from 'hsi/components/table/ManagedGridTable';
import PaginationButtons from 'hsi/components/PaginationButtons';

// Utils
import useGetTableColumns from './useReportTableColumns';
import useStyles from './styles';
import useFormatRows from 'hsi/components/table/ManagedTable/useFormatRows';
import useManagedPagination from 'hsi/components/table/ManagedTable/useManagedPagination';
import usePaginateRows from 'hsi/components/table/ManagedTable/usePaginateRows';

import {T} from 'hsi/i18n';

const perPage = 5;

function ReportList() {
    const classes = useStyles();
    const columns = useGetTableColumns();
    const {reportList: rows} = useSelector((state: RootReducer) => state.exportedReports);
    const [sortingDirection, setSortingDirection] = useState<'desc' | 'asc'>('desc');
    const {page, pages, setPage} = useManagedPagination(rows?.length ?? 0, perPage);

    const onSortClick = () => {
        if (sortingDirection === 'asc') {
            setSortingDirection('desc');
        } else {
            setSortingDirection('asc');
        }
    };

    const sortedRows = useMemo(() => {
        return sortingDirection === 'asc' ? [...rows].reverse() : rows;
    }, [rows, sortingDirection]);

    // Apply pagination
    const paginatedRows = usePaginateRows(sortedRows, page, perPage);

    // Apply formatting
    const formattedRows = useFormatRows(paginatedRows, columns);

    return (
        <>
            <Heading className={classes.title}>{T('exportReports.list.title')}</Heading>
            {rows?.length > 0 && (
                <>
                    <ManagedGridTable
                        columns={columns}
                        rowData={formattedRows}
                        caption={T('exportReports.list.title')}
                        sortColumn={'date'}
                        sortDirection={sortingDirection}
                        onSortClick={onSortClick}
                    />
                    <PaginationButtons page={page} pages={pages} setPage={setPage} />
                </>
            )}
        </>
    );
}

export default ReportList;
