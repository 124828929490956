import {useMemo} from 'react';

import {T} from 'hsi/i18n';
import { ColumnDefinition } from 'hsi/components/table/ManagedTable/types';
import {TDateISO} from 'hsi/types/dates';
import {formatTo} from 'hsi/utils/dates';

type reportListRow = {
    date: TDateISO;
    url: string;
}
type Columns = keyof reportListRow;
type TableColumnDefinition = ColumnDefinition<Columns>;


export default function useGetTableColumns() {
    return useMemo<TableColumnDefinition[]>(() => {
        const columns = [
            {
                name: 'date',
                label: T('exportReports.list.table.date'),
                horizontalAlign: 'start',
                width: '1fr',
                sortable: true,
                format: (row: reportListRow) => <span>{formatTo(row.date, undefined, 'd LLLL yyyy')}</span>,
            } as TableColumnDefinition,
            {
                name: 'url',
                label: T('exportReports.list.table.url'),
                width: '2fr',
                horizontalAlign: 'start',
                format: (row: reportListRow) => <a href={row.url}>{row.url}</a>,
            }as TableColumnDefinition,
        ]

        return columns;
    }, [])
}
