//TODO convert to TS
//TODO could probably change to use SimpleMenu
//TODO Combine into a single component, and use Context.Provider? Actually no, ReportsProvider is not a context,
//it is doing... something? I need to investigate
import React, {useState} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Divider, Popover, MenuList, MenuItem, ListItemText} from '@mui/material';
import {useSelector} from 'react-redux';

//Components
import Button from 'hsi/components/Button';
import BuildHSIReportModal from 'hsi/components/BuildHSIReportModal';
import ExportWithNotesModal from 'hsi/components/ExportWithNotesModal';
import EditReportModal from 'hsi/containers/Reports/EditReportModal';
import ConditionalButton from 'hsi/components/ConditionalButton';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useReports, {ReportsProvider} from 'hsi/hooks/useReports';
import useQueryContext from 'hsi/hooks/useQueryContext';
import useIsMultipleSearch from 'hsi/hooks/useIsMultipleSearch';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useFlags from 'hsi/hooks/useFlags';

//Other
import {T} from 'hsi/i18n';
import useStyles from './../styles';


//The component
const CreateReportModal = React.forwardRef(function CreateReportModal(props, ref) {
    const {createReport: createReportConfig} = useConfig();
    const isMultipleSearch = useIsMultipleSearch();
    const {actions} = useReports();
    const queryContext = useQueryContext();
    const {track} = useEventTrack();
    const {reportList} = useSelector((state) => state.exportedReports);
    const {classes} = useStyles();
    const {isSharableDashboardsEnabled} = useFlags();

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [isHSIReportModalOpen, setHSIReportModalOpen] = useState(false);
    const [isExportWithNotesModalOpen, setExportWithNotesModalOpen] = useState({
        isOpen: false,
        type: 'notes',
    });

    const isScheduleReportEnabled = () => !isMultipleSearch;

    const openMenu = (e) => {
        track('reportCreateClicked');
        setMenuAnchorEl(e.currentTarget);
    };
    const closeMenu = () => setMenuAnchorEl(null);
    const isMenuOpen = Boolean(menuAnchorEl);
    const toggleOpen = () => {
        closeMenu();
        setHSIReportModalOpen(!isHSIReportModalOpen);
    };
    const toggleExportWithNotesOpen = (type) => {
        closeMenu();
        setExportWithNotesModalOpen({type, isOpen: !isExportWithNotesModalOpen.isOpen});
    };

    return (
        <>
            <Button
                {...props}
                onClick={openMenu}
                priority="cta"
                endIcon={<ExpandMoreIcon />}
                ref={ref}
            >
                {T('savedsearchbar.buildreport.button')}
            </Button>

            <Popover
                open={isMenuOpen}
                anchorEl={menuAnchorEl}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuList autoFocus>
                    {createReportConfig.hasExternalReport && (
                        <MenuItem onClick={toggleOpen}>
                            <ListItemText>{T('savedsearchbar.buildreport.tooltip')}</ListItemText>
                        </MenuItem>
                    )}

                    <MenuItem
                        onClick={() => {
                            track('snapshotExportClicked', {
                                copy: isSharableDashboardsEnabled
                                    ? T(
                                          'savedsearchbar.buildreport.reportOptions.exportToPDFWithNotes',
                                      )
                                    : T('savedsearchbar.buildreport.reportOptions.exportAsPDF'),
                            });
                            toggleExportWithNotesOpen('notes');
                        }}
                    >
                        <ListItemText>
                            {isSharableDashboardsEnabled
                                ? T('savedsearchbar.buildreport.reportOptions.exportToPDFWithNotes')
                                : T('savedsearchbar.buildreport.reportOptions.exportAsPDF')}
                        </ListItemText>
                    </MenuItem>

                    <ConditionalButton
                        disabledTooltip={T('savedsearchbar.scheduleReportTooltip')}
                        useConditionHook={isScheduleReportEnabled}
                    >
                        <MenuItem
                            onClick={() => {
                                closeMenu();
                                actions.openModal();
                                track('reportScheduleOpen');
                            }}
                        >
                            <ListItemText>
                                {T('savedsearchbar.buildreport.reportOptions.scheduleReport')}
                            </ListItemText>
                        </MenuItem>
                    </ConditionalButton>
                    {isSharableDashboardsEnabled && (
                        <>
                            <Divider className={classes.divider} />
                            <MenuItem
                                disabled={reportList?.length === 0}
                                onClick={() => {
                                    toggleExportWithNotesOpen('list');
                                }}
                            >
                                <ListItemText>
                                    {T('savedsearchbar.buildreport.reportOptions.viewReports')}
                                </ListItemText>
                            </MenuItem>
                        </>
                    )}
                </MenuList>
            </Popover>

            <EditReportModal
                acceptButtonText={T('reports.editReportModal.createScheduleAcceptButton')}
                onAccept={actions.createReport}
                titleText={T('reports.editReportModal.createScheduleTitle')}
            />

            <BuildHSIReportModal
                open={isHSIReportModalOpen}
                closeFunc={toggleOpen}
                search={queryContext}
            />

            {queryContext?.savedSearchId && (
                <ExportWithNotesModal
                    handleClose={toggleExportWithNotesOpen}
                    isMultipleSearch={isMultipleSearch}
                    modalProps={isExportWithNotesModalOpen}
                    savedSearchName={queryContext.savedSearch.name}
                    savedSearchId={queryContext.savedSearchId}
                />
            )}
        </>
    );
});

export default React.forwardRef(function CreateReport(props, ref) {
    return (
        <ReportsProvider {...props}>
            <CreateReportModal {...props} ref={ref} />
        </ReportsProvider>
    );
});
