import {useEffect, useState, useCallback} from 'react';

// Hooks & actions
import {setPassword} from 'hsi/slices/exportedReports';
import useRandomPasswordGenerator from 'hsi/hooks/useRandomPasswordGenerator';
import {useAppDispatch} from 'hsi/hooks/useRedux';

// Components
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TextField from 'hsi/components/TextField';
import IconRouter from 'hsi/components/IconRouter';
import Button from 'hsi/components/Button';

import useStyles from './styles';

import {T} from 'hsi/i18n';

type PasswordGeneratorProps = {
    setIsPasswordValid: (arg0: boolean) => void;
    isListPage: boolean;
};

type Validation = {
    hint: string;
    pattern: RegExp;
    isValid: boolean;
};

const minLength = 8;
const maxLength = 64;

const createValidations = (password: string): Validation[] => [
    {
        hint: T('exportReports.passwordGenerator.validations.eightCharacters'),
        pattern: /^.{8,}$/, // test if the string has at least 8 characters
        isValid: /^.{8,}$/.test(password),
    },
    {
        hint: T('exportReports.passwordGenerator.validations.oneUppercaseCharacter'),
        pattern: /^.*[A-Z].*$/, // test if the string contains at least one uppercase character
        isValid: /^.*[A-Z].*$/.test(password),
    },
    {
        hint: T('exportReports.passwordGenerator.validations.oneNumericCharacter'),
        pattern: /^.*[0-9].*$/, // test if the string contains at least one numeric character
        isValid: /^.*[0-9].*$/.test(password),
    },
    {
        hint: T('exportReports.passwordGenerator.validations.oneNonAlphanumericCharacter'),
        pattern: /^.*[^a-zA-Z\d].*$/, // test if the string is nor a lowercase character, nor a uppercase character, nor a number e.g. !#%,+-/)"
        isValid: /^.*[^a-zA-Z\d].*$/.test(password), // Fixed the pattern test here - it was using wrong pattern
    },
];

function PasswordGenerator({setIsPasswordValid, isListPage}: PasswordGeneratorProps) {
    const classes = useStyles();
    const [currentPassword, setCurrentPassword] = useState(
        useRandomPasswordGenerator(minLength, maxLength),
    );
    const [isVisible, setIsVisible] = useState(true);
    const [validations, setValidations] = useState<Validation[]>(() =>
        createValidations(currentPassword),
    );
    const dispatch = useAppDispatch();

    const handleChange = useCallback(
        (newPassword: string) => {
            const newValidations = createValidations(newPassword);
            const isValidNewPassword = newValidations.every(({pattern}) =>
                pattern.test(newPassword),
            );

            setCurrentPassword(newPassword);
            setValidations(newValidations);
            setIsPasswordValid(isValidNewPassword);

            if (isValidNewPassword) dispatch(setPassword({password: newPassword}));
        },
        [dispatch, setIsPasswordValid],
    );

    const InputIcon = () => (
        <Button
            className={classes.visibleIcon}
            size="small"
            onClick={() => {
                setIsVisible(!isVisible);
            }}
            priority="primary"
        >
            {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </Button>
    );

    useEffect(() => {
        const isValid = validations.every(({pattern}) => pattern.test(currentPassword));
        setIsPasswordValid(isValid);
    }, [setIsPasswordValid, currentPassword, validations]);

    return (
        <>
            {!isListPage ? (
                <div className={classes.copy}>{T('exportReports.passwordGenerator.copy')}</div>
            ) : null}
            <div className={classes.inputWrapper}>
                <label htmlFor={'password'} className={classes.label}>
                    {T('exportReports.passwordGenerator.label')}
                </label>
                <TextField
                    className={classes.annotationInput}
                    error={currentPassword.length > maxLength}
                    inputProps={{maxLength}}
                    id={'password'}
                    multiline={false}
                    onChange={(e) => handleChange(e.target.value)}
                    placeholder={T('exportReports.passwordGenerator.placeholder')}
                    value={currentPassword}
                    variant="outlined"
                    type={isVisible ? 'text' : 'password'}
                    {...{InputProps: {endAdornment: <InputIcon />}}}
                />
            </div>
            {!isListPage ? (
            <div className={classes.validationRules}>
                {validations.map((validation, key) => {
                    return (
                        <div
                            role="status"
                            aria-live="polite"
                            key={`validation-rule${key}`}
                            className={classes.rule}
                        >
                            {validation.isValid ? (
                                <IconRouter
                                    aria-hidden="true"
                                    name="f-check"
                                    className={classes.checkIcon}
                                />
                            ) : (
                                <IconRouter
                                    aria-hidden="true"
                                    name="close"
                                    className={classes.crossIcon}
                                />
                            )}
                            <span className={classes.screenReaderOnly}>
                                {validation.isValid ? 'Requirement met' : 'Requirement not met'}
                            </span>
                            <span>{validation.hint}</span>
                        </div>
                    );
                })}
            </div>
            ) : null}
        </>
    );
}

export default PasswordGenerator;
