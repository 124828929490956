import {combineReducers, Reducer} from 'redux';
import {RouterState} from 'redux-first-history';

import autocompleteReducer from './autocompleteReducer';
//import mentionsReducer from './mentionsReducer';
import notificationsReducer from './notificationsReducer';
import reportReducer from './reportReducer';
import resultReducer from './resultReducer';
import savedSearchReducer from './savedSearchReducer';
import tagsReducer from './tagsReducer';
import exportReducer from './exportReducer';
import userReducer from './userReducer';
import allTags from './allTagsReducer';

//slices
import alerts from 'hsi/slices/alerts';
import ariaAnnounce from 'hsi/slices/ariaAnnounce';
import chart from 'hsi/slices/chart';
import cardPersistState from 'hsi/slices/cardPersistState';
import exportedReports from 'hsi/slices/exportedReports';
import facebookAuthentication from 'hsi/slices/facebookAuthentication';
import filters from 'hsi/slices/filters';
import filtersOpen from 'hsi/slices/filtersOpen';
import query from 'hsi/slices/query';
import mentions from 'hsi/slices/mentions';
import {AllFilteringState} from 'hsi/types/filters';
import quickSearchPage from 'hsi/slices/quickSearchPage';
import quickSearchResultsPage from 'hsi/slices/quickSearchResultsPage';
import queryData from 'hsi/slices/queryData';
import queryUserData from 'hsi/slices/queryUserData';
import socialPanels from 'hsi/slices/socialPanels';
import searchResults from 'hsi/slices/searchResults';


export default function rootReducer(routerReducer?: Reducer<RouterState>) {
    return combineReducers({
        autocomplete: autocompleteReducer,
        notifications: notificationsReducer,
        reports: reportReducer,
        results: resultReducer,
        search: savedSearchReducer,
        tags: tagsReducer,
        user: userReducer,
        pdfExport: exportReducer,
        //Slices
        alerts,
        ariaAnnounce,
        cardPersistState,
        chart,
        exportedReports,
        facebookAuthentication,
        filtersOpen,
        filters: filters as any as Reducer<AllFilteringState>, //Need to convert filters slice to TS to remove this cast
        query,
        allTags,
        mentions,
        quickSearchPage,
        quickSearchResultsPage,
        socialPanels,
        queryData,
        queryUserData,
        searchResults,

        //additional
        ...(routerReducer ? {router: routerReducer} : undefined),
    });
}

export type RootReducer = ReturnType<ReturnType<typeof rootReducer>>;
export type CreateRootReducer = typeof rootReducer; //TODO this should really be a generic of some kind, to work with the streams app
