export const reports = [
    {
        id: 2884920293,
        date: "2024-10-16T18:46:01.933Z",
        url: '/reports/userid/38844039'
    },
    {
        id: 2884009029,
        date: "2024-09-12T13:26:41.933Z",
        url: '/reports/userid/30234039'
    },
    {
        id: 1844009029,
        date: "2024-07-27T09:45:20.353Z",
        url: '/reports/userid/30234039'
    },
]