import {randexp} from 'randexp';

export default function useRandomPasswordGenerator(minLength: number, maxLength: number) {
    const baseRegex = new RegExp('/[a-z0-9A-Z]{' + minLength + ',' + maxLength + '}/');
    const randomBaseString = randexp(baseRegex);
    const randomNonAlphaNumericString = randexp(/[^a-zA-Z\d]{1,8}/);
    const chars = [...randomBaseString.split(''), ...randomNonAlphaNumericString.split('')];
    chars.sort(() => 0.5 - Math.random()).join('');
    const mergedChars = chars.join('');
    mergedChars.replace(/\s/g, "");

    return mergedChars.slice(0, maxLength - 1);
}
