import makeStyles from '@mui/styles/makeStyles';

import {Theme, isV2} from 'hsi/types/theme';

const MAX_WIDTH = '800px';

export default makeStyles((theme: Theme) => {
    const v2 = isV2(theme);

    return {
        createPDFExport: {
            color: theme.colors.textDefault,
            fontSize: '16px',

            '& .MuiDialog-paper': {
                height: 'auto',
                maxWidth: MAX_WIDTH,
                flex: `1 1 ${MAX_WIDTH}`,
                overflowY: 'visible',
            },
        },
        leftAndRight: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
        },
        clear: {
            marginRight: 'auto',
            color: theme.colors.primaryBlue100,
        },

        cardWrapper: {
            pointerEvents: 'none',
            marginTop: '10px',
        },
        dates: {
            paddingBottom: '2px',
            fontWeight: v2 ? theme.fonts.bodyNormal.fontWeight : 700,
            fontSize: v2 ? theme.fonts.bodyNormal.fontSize : '16px',
        },
        time: {
            color: '#000',
        },
        dateHeader: {
            display: 'block',
        },
        inputWrapper: {
            position: 'relative',
        },
        sectionMargin: {
            margin: v2 ? '16px 0 6px 0' : '16px 0 0 0',
        },
        fieldLabel: {
            fontWeight: v2 ? theme.fonts.bodyNormal.fontWeight : 700,
            fontSize: v2 ? theme.fonts.bodyNormal.fontSize : '16px',
        },
        clearField: {
            ...theme.mixin.blankButton,
            ...(v2
                ? theme.fonts.bodyStrong
                : {
                      fontSize: '16px',
                      fontWeight: theme.font.bold,
                  }),

            color: v2 ? theme.colors.textHyperlink : theme.colors.secondaryBlue100,
            lineHeight: '24px',
        },
        annotationInput: {
            width: '100%',
            marginBottom: '15px',
        },
        nameInput: {
            height: '50px',
        },
        helperText: {
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            margin: '4px -12px 0 0',
        },
        warning: {
            display: v2 ? 'none' : 'flex',
            alignItems: 'center',
            gap: 4,
        },
        warningIcon: {
            fill: theme.colors.primaryRed100,
            width: '21px',
            height: '21px',
            padding: '5px',
            backgroundColor: theme.colors.primaryRed40,
            borderRadius: '50%',
        },
        characterLimit: {
            flex: '1 0 auto',
            textAlign: 'right',
            color: theme.colors.textDefault,
        },

        primaryCta: {
            color: theme.colors.primaryBlue100,
        },

        // Password Generator form
        passwordGenerator: {
            '& .MuiDialog-paper': {
                maxWidth: '600px',
                flex: '1 1 600px',
            },
        },
        title: {
            ...(v2
                ? theme.fonts.headtitleStrong
                : {
                      fontSize: '17px',
                      fontWeight: 'bold',
                  }),
            paddingBottom: '15px',
            margin: v2 ? 0 : undefined,
        },

        copy: {
            ...(v2
                ? theme.fonts.bodyNormal
                : {
                      fontSize: '16px',
                  }),
            paddingBottom: '10px',
        },

        label: {
            paddingBottom: '5px',
            fontWeight: v2 ? theme.fonts.bodyNormal.fontWeight : 700,
            fontSize: v2 ? theme.fonts.bodyNormal.fontSize : '16px',
        },

        validationRules: {
            ...(v2
                ? theme.fonts.bodyNormal
                : {
                      fontSize: '16px',
                  }),
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridRowGap: '10px',
        },
        visibleIcon: {
            display: 'flex',
            cursor: 'pointer',
            border: 'none',
            padding: 0,
            minWidth: 'inherit',
            '&:hover': {
                border: 'none',
                backgroundColor: theme.colors.white,
            },
        },
        rule: {
            display: 'flex',
            alignItems: 'center',
        },
        checkIcon: {
            fill: theme.colors.positive,
        },
        crossIcon: {
            fill: theme.colors.negative,
        },
        screenReaderOnly: {
            position: 'absolute',
            width: '1px',
            height: '1px',
            padding: '0',
            margin: '-1px',
            overflow: 'hidden',
            clip: 'rect(0, 0, 0, 0)',
            whiteSpace: 'nowrap',
            borderWidth: '0',
        },
    };
});
