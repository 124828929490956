// import {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {TDateISO} from 'hsi/types/dates';

// Mock data until hooked to db
import {reports} from 'hsi/test-utils/mockData/exportedReportList';

type reportListType = {
    url: string;
    date: TDateISO;
    id: number,
}

export type ExportedReportsType = {
    password: string | null,
    reportList: reportListType[];
    savedSearchId: string;
};

export const initialState = {
    password: null,
    reportList: reports, // [] TODO EA-2242 replace with empty array when we have API ready to get report list
    savedSearchId: '',
};

const slice = createSlice({
    name: 'exportedReports',
    initialState,
    reducers: {
        createReport: (state) => {
            // TODO EA-2242 hook up
        },
        editReport: (state) => {
            // TODO EA-2242 hook up 
        },
        deleteReport: (state, payload) => {
            // TODO EA-2242 hook up
        },
        updatePassword: (state) => {
            // TODO EA-2242 hook up,
        },
        setPassword: (state, {payload: {password}}) => {
            state.password = password
        },
    },
});

export const {
    createReport,
    editReport,
    deleteReport,
    updatePassword,
    setPassword,
} = slice.actions;
export default slice.reducer;
