import {memo, useCallback, useRef, useMemo, useState, useEffect, MutableRefObject} from 'react';
import cn from 'classnames';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';

// Components
import Annotation from './Annotation';
import Button from 'hsi/components/Button';
import ChipMultipleSelect from 'hsi/components/ChipMultipleSelect';
import {Dialog, DialogContent, DialogActions} from 'hsi/components/Dialog';
import PulseLoader from 'hsi/components/PulseLoader';
import Heading from 'hsi/components/aria/Heading';
import Banner from 'hsi/components/Banner';
import PasswordGenerator from './PasswordGenerator';

// Hooks
import useConfig from 'hsi/hooks/useConfig';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useFlags from 'hsi/hooks/useFlags';
import useGetCards from 'hsi/hooks/useGetCards';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
import useStyles from './styles';
import useTimezone from 'hsi/hooks/useTimezone';
import {VisibleContainer} from 'hsi/hooks/useOnVisible';

// Actions
import {exportToPDF, saveExportConfig} from 'hsi/actions/exportActions';

// Contexts
import {IsCardInteractivityDisabled} from 'hsi/contexts/IsCardInteractivityDisabled';

// Utils
import {getRelativePos} from 'hsi/utils/scroll';
import {formatCardTitleAsString} from 'hsi/components/Card/CardTitle';
import {formatTo} from 'hsi/utils/dates';
import * as dates from 'hsi/utils/dates';

// Other
import {T} from 'hsi/i18n';

//Types
import {AnnotationKey, ChartKey} from 'hsi/types/charts';
import {CardComponentConfig} from 'hsi/types/cards';
import {Breakdowns, Aggregates} from 'hsi/types/filters';
import ReportList from './ReportList';

type ExportWithNotesModalProps = {
    handleClose: () => void;
    isMultipleSearch?: boolean;
    modalProps: {
        isOpen: boolean;
        type: 'notes' | 'passwordGenerator' | 'list';
    };
    savedSearchId: number;
    savedSearchName: string;
};

// Consts
const visibleContainerOptions = {margin: 250};
const MAX_LENGTH = 1000;

//The component
const ExportWithNotesModal = memo(
    ({handleClose, modalProps, savedSearchId, savedSearchName}: ExportWithNotesModalProps) => {
        const classes = useStyles();
        const {searchResults: config, appSource} = useConfig();
        const dispatch = useAppDispatch();
        const {track, trackWithSearchData} = useEventTrack();
        const flags = useFlags();
        const _cards = useGetCards(config);
        const timezone = useTimezone();
        const {isSharableDashboardsEnabled} = flags;

        // Selectors
        const {cardsToExport, annotations: initialAnnotions} = useAppSelector(
            (state) => state.pdfExport,
        );
        const dateRange = useAppSelector((state) => state.filters.dateRange);
        // TODO: Once filters are typed this should be updated
        const cardPersistState = useAppSelector((state) => state.cardPersistState);
        const charts = useAppSelector((state) => state.chart);

        // State
        const [hasError, setHasError] = useState(false);
        const [hasAnyAnnotations, setHasAnyAnnotations] = useState(() => {
            const initial = initialAnnotions?.[savedSearchId];

            if (!initial) {
                return false;
            }

            return Object.values(initial).some((annotation) => !!annotation);
        });
        const [modalContentType, setModalContentType] = useState<
            'notes' | 'passwordGenerator' | 'list'
        >(modalProps.type);
        const [isPasswordValid, setIsPasswordValid] = useState(false);

        // Refs
        const nameRef = useRef<Partial<Record<AnnotationKey, string | undefined>> | null>(null);
        const annotationsRef = useRef<Partial<Record<AnnotationKey, string | undefined>> | null>(
            null,
        );
        const elementsRef = useRef<Partial<Record<ChartKey, HTMLDivElement | null>> | null>(null);
        const modalAreaRef = useRef<HTMLDivElement | null>(null);

        if (!annotationsRef?.current) {
            annotationsRef.current = initialAnnotions ? initialAnnotions?.[savedSearchId] : {};
        }

        // Some formatting for the dropdown checkboxes
        const cards = useMemo(
            () =>
                _cards.map((card) => ({
                    ...card,
                    label: formatCardTitleAsString(
                        card.title,
                        cardPersistState[card.name as keyof Breakdowns]?.breakdown,
                    ),
                })),
            [_cards, cardPersistState],
        );

        const defaultSelectedCards = useMemo(
            () =>
                (cardsToExport?.[savedSearchId] || []).reduce<Partial<Record<ChartKey, boolean>>>(
                    (output, cardName) => {
                        output[cardName] = true;

                        return output;
                    },
                    {} as Partial<Record<ChartKey, boolean>>,
                ),
            [cardsToExport, savedSearchId],
        );

        const [selectedCards, setSelectedCards] =
            useState<Partial<Record<ChartKey, boolean>>>(defaultSelectedCards);

        const cardPickOptions = useMemo(
            () =>
                cards.map(({name, title}) => ({
                    name,
                    label: formatCardTitleAsString(
                        title,
                        cardPersistState[name as keyof Breakdowns]?.breakdown ?? undefined,
                        cardPersistState[name as keyof Aggregates]?.aggregate ?? undefined,
                    ),
                })),
            [cardPersistState, cards],
        );

        const selectedCardPickOptions = useMemo(
            () => cardPickOptions.filter((cardPickOption) => !!selectedCards[cardPickOption.name]),
            [cardPickOptions, selectedCards],
        );

        const selectedCardsLoaded = cards.every((card) => {
            if (isEmpty(selectedCards)) return charts[card.name].loaded === true;
            else return !selectedCards[card.name] || charts[card.name].loaded === true;
        });

        //Callbacks
        //-annotation callbacks
        const checkAnnotationErrors = useCallback(() => {
            const annotations = annotationsRef.current;
            if (isEmpty(annotations) || !annotations) {
                setHasError(false);
                setHasAnyAnnotations(false);
            } else {
                const annotationsArr = Object.values(annotations);

                setHasError(annotationsArr.some((annotation) => annotation?.length > MAX_LENGTH));
                setHasAnyAnnotations(annotationsArr.some((annotation) => annotation?.length > 0));
            }
        }, [annotationsRef]);

        const setAnnotation = useCallback(
            (name: AnnotationKey, value: string | undefined) => {
                annotationsRef.current = {...annotationsRef.current, [name]: value};
                checkAnnotationErrors();
            },
            [annotationsRef, checkAnnotationErrors],
        );

        const clearAnnotations = useCallback(
            (name?: AnnotationKey) => {
                if (name) {
                    setAnnotation(name, '');
                } else {
                    annotationsRef.current = {};
                    checkAnnotationErrors();
                }
            },
            [annotationsRef, checkAnnotationErrors, setAnnotation],
        );

        const setName = useCallback(
            (name: AnnotationKey, value: string | undefined) => {
                nameRef.current = {[name]: value};
            },
            [nameRef],
        );

        const onClose = useCallback(() => {
            handleClose?.();
        }, [handleClose]);

        const onExportAsPDF = async () => {
            const annotations = annotationsRef.current;
            trackWithSearchData('dashboardPdfClicked', {
                annotations,
                selectedCards: (Object.keys(selectedCards) as ChartKey[]).filter(
                    (cardName) => selectedCards[cardName],
                ),
            });

            await dispatch(
                saveExportConfig({
                    annotations: {...annotations, [savedSearchId]: annotations},
                    cardsToExport: {
                        ...cardsToExport,
                        [savedSearchId]: (Object.keys(selectedCards) as ChartKey[]).filter(
                            (cardName) => selectedCards[cardName],
                        ),
                    },
                }),
            );
            const onSuccess = () => {
                trackWithSearchData('dashboardPdfExported', {});
            };

            // TODO: This action needs to be typed for onSuccess to be valid
            dispatch(exportToPDF(flags!, appSource, selectedCardsLoaded, onSuccess as any));
            onClose();
        };

        const onCancel = () => {
            track('reportCancelClicked');
            onClose();
        };

        const onExportReport = () => {
            setModalContentType('passwordGenerator');
        };

        const onPasswordSave = () => {
            setModalContentType('list');
        };

        const defaultReportName = T('exportToPDF.header.savedSearch', {
            date: formatTo(new Date(), undefined, 'yyyy-LL-dd h:mm:ss'),
            name: savedSearchName,
        });

        useEffect(() => {
            nameRef.current = {name: defaultReportName};
            checkAnnotationErrors();
        }, [checkAnnotationErrors, defaultReportName, nameRef]);

        useEffect(() => {
            setModalContentType(modalProps.type);
        }, [modalProps.type]);

        const saveCardsToExport = useCallback(
            (selectedOptions: typeof cardPickOptions) => {
                setSelectedCards(
                    selectedOptions.reduce<typeof selectedCards>((output, {name}) => {
                        output[name] = true;

                        return output;
                    }, {} as typeof selectedCards),
                );
            },
            [setSelectedCards],
        );

        const scrollHandler = useCallback(
            (event: React.UIEvent<HTMLElement>, cardName: ChartKey) => {
                event.stopPropagation();
                if (modalAreaRef?.current && elementsRef?.current?.[cardName]) {
                    modalAreaRef.current.scrollTop = getRelativePos(
                        elementsRef.current[cardName]!,
                        modalAreaRef.current,
                    ).top;
                }
            },
            [],
        );

        const deleteHandler = useCallback(
            (deletedOption: {name: string}) =>
                setSelectedCards((currentSelectedCards: typeof selectedCards) => ({
                    ...currentSelectedCards,
                    [deletedOption.name]: false,
                })),
            [setSelectedCards],
        );

        //Render
        //-this used to be in a useMemo, but for some reason that prevented annotations from updating when using the clear all button
        const exportAll = Object.values(selectedCards).every((val) => !val);

        const selectCardsConfig = useMemo(
            () => (exportAll ? cards : cards.filter(({name}) => !!selectedCards[name])),
            [cards, exportAll, selectedCards],
        );

        const cardSections = useMemo(
            () =>
                selectCardsConfig.map((card) => {
                    return (
                        <CardAnnotation
                            key={card.name}
                            card={card}
                            setAnnotation={setAnnotation}
                            annotationsRef={annotationsRef}
                            elementsRef={elementsRef}
                            breakdown={cardPersistState[card.name as keyof Breakdowns]?.breakdown}
                            aggregate={
                                cardPersistState[card.name as keyof Aggregates]?.aggregate ||
                                undefined
                            }
                            classes={classes}
                        />
                    );
                }),
            [cardPersistState, classes, selectCardsConfig, setAnnotation, annotationsRef],
        );

        const annotationsForm = useMemo(
            () => (
                <>
                    <Annotation
                        label={
                            <>
                                <span aria-hidden="true">
                                    {capitalize(T('exportToPDF.annotations.name'))}
                                </span>
                                <span className="offscreen">
                                    {T('exportToPDF.annotations.nameLabel')}
                                </span>
                            </>
                        }
                        maxLength={200}
                        multiline={false}
                        name="name"
                        placeholder={T('exportToPDF.annotations.namePlaceholder')}
                        setValue={setName}
                        valuesRef={nameRef}
                        isName
                    />
                    <div className={classes.dates} data-testid="exportHeaderDates">
                        <span className={classes.dateHeader}>
                            {T('exportToPDF.annotations.date')}
                        </span>
                        <time
                            className={classes.time}
                            dateTime={dates.formatTo(dateRange.startDate, timezone, 'yyyy-LL-ddZZ')}
                        >
                            {dates.formatTo(dateRange.startDate, timezone, 'DDD')}
                        </time>{' '}
                        -{' '}
                        <time
                            className={classes.time}
                            dateTime={dates.formatTo(dateRange.startDate, timezone, 'yyyy-LL-ddZZ')}
                        >
                            {dates.formatTo(dateRange.endDate, timezone, 'DDD')}
                        </time>{' '}
                        {`(${dates.formatTo(dateRange.endDate, timezone, 'ZZZZ')})`}
                    </div>

                    <Annotation
                        clearLabel={
                            <>
                                <span aria-hidden="true">{T('exportToPDF.annotations.clear')}</span>
                                <span className="offscreen">
                                    {T('exportToPDF.annotations.clearSummaryAccessible')}
                                </span>
                            </>
                        }
                        label={
                            <>
                                <span aria-hidden="true">
                                    {capitalize(T('exportToPDF.annotations.summary'))}
                                </span>
                                <span className="offscreen">
                                    {T('exportToPDF.annotations.summaryLabel')}
                                </span>
                            </>
                        }
                        maxLength={MAX_LENGTH}
                        name="summary"
                        placeholder={T('exportToPDF.annotations.summaryPlaceholder')}
                        setValue={setAnnotation}
                        valuesRef={annotationsRef}
                    />

                    <div className={cn(classes.fieldLabel, classes.sectionMargin)}>
                        {T('exportToPDF.annotations.cardsSelector')}
                    </div>

                    <ChipMultipleSelect
                        applyHandler={saveCardsToExport}
                        btnLabel="exportToPDF.cardSelectLbl"
                        chipsLbl="exportToPDF.selectedCards"
                        deleteHandler={deleteHandler}
                        options={cardPickOptions}
                        placeholder={T('exportToPDF.annotations.byDefault')}
                        selectedOptions={selectedCardPickOptions}
                        scrollHandler={scrollHandler}
                    />
                    {cardSections}
                </>
            ),
            [
                cardPickOptions,
                cardSections,
                classes,
                dateRange,
                deleteHandler,
                saveCardsToExport,
                scrollHandler,
                selectedCardPickOptions,
                setAnnotation,
                setName,
                timezone,
            ],
        );

        if (!modalProps.isOpen) return null;

        return (
            <Dialog
                className={cn(
                    classes.createPDFExport,
                    modalContentType === 'passwordGenerator' ? classes.passwordGenerator : {},
                )}
                onClose={onClose}
                open={modalProps.isOpen}
                title={T('exportToPDF.annotations.modalTitle')}
            >
                <VisibleContainer options={visibleContainerOptions}>
                    <DialogContent ref={modalAreaRef}>
                        {modalContentType === 'notes' ? annotationsForm : null}
                        {modalContentType === 'list' ? (
                            <Banner variant="success">
                                {T('exportReports.passwordGenerator.success')}
                            </Banner>
                        ) : null}
                        {modalContentType !== 'notes' && (
                            <>
                                <Heading className={classes.title}>
                                    {T(`exportReports.${modalContentType}.title`)}
                                </Heading>
                                <PasswordGenerator
                                    isListPage={modalContentType === 'list'}
                                    setIsPasswordValid={setIsPasswordValid}
                                />
                            </>
                        )}
                        {modalContentType === 'list' ? <ReportList /> : null}
                    </DialogContent>
                </VisibleContainer>
                <DialogActions>
                    {hasAnyAnnotations && modalContentType === 'notes' && (
                        <Button
                            priority="primary"
                            onClick={() => clearAnnotations()}
                            className={classes.clear}
                        >
                            {T('exportToPDF.annotations.clearAll')}
                        </Button>
                    )}
                    {modalContentType !== 'list' ? (
                        <Button onClick={onCancel} priority="secondary">
                            {T('exportToPDF.goBack')}
                        </Button>
                    ) : (
                        <Button onClick={onClose} priority="cta">
                            {T('exportReports.close')}
                        </Button>
                    )}
                    {/* Shareable dashboard CTAs */}
                    {isSharableDashboardsEnabled && modalContentType === 'notes' && (
                        <>
                            <Button
                                priority="primary"
                                className={classes.primaryCta}
                                onClick={onExportAsPDF}
                                disabled={!selectedCardsLoaded || !!hasError}
                            >
                                {selectedCardsLoaded ? T('exportToPDF.save') : <PulseLoader />}
                            </Button>
                            <Button
                                priority="cta"
                                onClick={onExportReport}
                                disabled={!selectedCardsLoaded || !!hasError}
                            >
                                {selectedCardsLoaded ? T('exportToPDF.create') : <PulseLoader />}
                            </Button>
                        </>
                    )}
                    {/* Use old CTAs when isSharableDashboardsEnabled is not enabled  */}
                    {!isSharableDashboardsEnabled && (
                        <Button
                            priority="cta"
                            onClick={onExportAsPDF}
                            disabled={!selectedCardsLoaded || !!hasError}
                        >
                            {selectedCardsLoaded ? T('exportToPDF.save') : <PulseLoader />}
                        </Button>
                    )}
                    {modalContentType === 'passwordGenerator' && (
                        <Button priority="cta" onClick={onPasswordSave} disabled={!isPasswordValid}>
                            {selectedCardsLoaded ? T('exportReports.save') : <PulseLoader />}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    },
);

export default ExportWithNotesModal;

type CardAnnotationProps = {
    card: CardComponentConfig;
    setAnnotation: (name: AnnotationKey, value: string | undefined) => void;
    annotationsRef: MutableRefObject<Partial<Record<AnnotationKey, string | undefined>> | null>;
    elementsRef: MutableRefObject<Partial<Record<ChartKey, HTMLDivElement | null>> | null>;
    breakdown: string | undefined; //Partial<Record<ChartKey, string>>;
    aggregate: string | undefined;
    classes: ReturnType<typeof useStyles>;
};

const CardAnnotation = memo(function CardAnnotation({
    card,
    setAnnotation,
    annotationsRef,
    elementsRef,
    breakdown,
    aggregate,
    classes,
}: CardAnnotationProps) {
    const cardTitle = formatCardTitleAsString(card.title, breakdown, aggregate).toLowerCase();

    return (
        <section key={card.name} data-testid={`${card.name}-section`}>
            <div
                ref={(element) => {
                    if (elementsRef?.current?.[card.name]) {
                        elementsRef.current[card.name] = element;
                    }
                }}
                className={cn(classes.cardWrapper, 'printMedia')}
            >
                <IsCardInteractivityDisabled.Provider value={true}>
                    <card.component height={card.height} title={card.title} renderWhenVisible />
                </IsCardInteractivityDisabled.Provider>
            </div>
            <Annotation
                clearLabel={
                    <>
                        <span aria-hidden="true">{T('exportToPDF.annotations.clear')}</span>
                        <span className="offscreen">
                            {T('exportToPDF.annotations.clearAccessible', {cardTitle})}
                        </span>
                    </>
                }
                label={
                    <>
                        <span aria-hidden="true">
                            {capitalize(T('exportToPDF.annotations.notes'))}
                        </span>
                        <span className="offscreen">
                            {T('exportToPDF.annotations.notesLabel', {cardTitle})}
                        </span>
                    </>
                }
                maxLength={MAX_LENGTH}
                name={card.name}
                setValue={setAnnotation}
                valuesRef={annotationsRef}
            />
        </section>
    );
});
