import cn from 'classnames';

import {useCallback, useMemo} from 'react';


import CharacterLimit from 'hsi/components/CharacterLimit';
import IconRouter from 'hsi/components/IconRouter';
import TextField, {TextFieldProps} from 'hsi/components/TextField';

import useForceUpdate from 'hsi/hooks/useForceUpdate';
import useStyles from './styles';
import useUniqueId from 'hsi/hooks/useUniqueId';

import {AnnotationKey} from 'hsi/types/charts';

import {T} from 'hsi/i18n';

type AnnotationProps = {
    clearLabel?: React.ReactNode;
    id?: string;
    isName?: boolean;
    label: React.ReactNode;
    maxLength?: number;
    multiline?: boolean;
    name: AnnotationKey;
    placeholder?: string;
    setValue: (name: AnnotationKey, value: string) => void;
    valuesRef: React.MutableRefObject<Partial<Record<AnnotationKey, string | undefined>> | null>;
};

function Annotation({
    clearLabel,
    id: _id,
    isName = false,
    label,
    maxLength = 1000,
    multiline = true,
    name,
    placeholder,
    setValue,
    valuesRef,
}: AnnotationProps) {
    const classes = useStyles();
    const forceUpdate = useForceUpdate();
    const id = useUniqueId(_id, 'annotation');

    const value = !!valuesRef?.current?.[name] ? (valuesRef.current[name] as string) : '';
    const isSummary = name === 'summary';

    const onChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
        (event) => {
            setValue(name, event.target.value);
            forceUpdate();
        },
        [name, setValue, forceUpdate],
    );

    const clearValue = useCallback(() => {
        setValue(name, '');
        forceUpdate();
    }, [name, setValue, forceUpdate]);

    const helperText = useMemo(() => {
        return (
            <div className={classes.helperText}>
                {value.length > maxLength && (
                    <div className={classes.warning} aria-live="assertive" role="alert">
                        <IconRouter
                            aria-hidden="true"
                            className={classes.warningIcon}
                            name="warning"
                        />
                        {T(`exportToPDF.annotations.warning.${isSummary ? 'summary' : 'notes'}`, {
                            max: maxLength,
                        })}
                    </div>
                )}
                {value.length > 0 && (
                    <CharacterLimit
                        className={classes.characterLimit}
                        current={value.length}
                        data-testid={`characterLimit-${name}`}
                        hideMaxAlert
                        max={maxLength}
                    />
                )}
            </div>
        );
    }, [classes, isSummary, maxLength, name, value]);

    return (
        <>
            <div className={cn(classes.leftAndRight, classes.sectionMargin)}>
                <label htmlFor={id} className={classes.fieldLabel}>
                    {label}
                </label>
                <label htmlFor={id} className="offscreen">
                    {T('exportToPDF.annotations.maxLengthLabel', {maxLength})}
                </label>
                {value && clearLabel && (
                    <button onClick={clearValue} className={classes.clearField}>
                        {clearLabel}
                    </button>
                )}
            </div>
            <div className={classes.inputWrapper}>
                <TextField
                    className={cn(classes.annotationInput, isName ? classes.nameInput : {})}
                    error={value?.length > maxLength}
                    helperText={helperText}
                    id={id}
                    inputProps={{maxLength: Math.round(maxLength * 1.5)}}
                    minRows={3}
                    multiline={multiline}
                    onChange={onChange}
                    placeholder={placeholder || T('exportToPDF.annotations.annotationPlaceholder')}
                    value={value}
                    variant="outlined"
                />
            </div>
        </>
    );
}

export default Annotation;
